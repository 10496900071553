<template>
    <div style="display: inline-block;margin-right: 10px;">
        <el-button type="text" size="medium" @click.stop="onEdit" v-if="title == '查看详情'">{{ title }}</el-button>
        <el-button type="primary" size="medium" @click.stop="onAdd" v-else>{{ title }}</el-button>
        <el-dialog :title="editTitle" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="50%"
            center>
            <!-- 表单提交 -->
            <el-form :model="params" :rules="rules" ref="form-ref" inline label-position="top">
                <section class="form-main">
                    <el-form-item label="项目类型" prop="workProjectType">
                        <el-select v-model="params.workProjectType" placeholder="请选择项目类型"
                            @change="getWorkProjectModelList" :disabled="title == '查看详情'">
                            <el-option v-for="item in workProjectTypeList" :label="item.codeLabel"
                                :value="item.codeValue" :key="item.codeValue">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="项目模板" prop="projectTemplateId">
                        <el-select v-model="params.projectTemplateId" placeholder="请选择项目模板" @change="getTemplateInfo"
                            :disabled="title == '查看详情'">
                            <el-option v-for="item in workProjectMoldelList" :label="item.workProjectName"
                                :value="item.projectTemplateId" :key="item.projectTemplateId">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <!-- 项目参数 -->
                    <div v-for="(item, index) in params.workProjectInstanceParamDtoList"
                        :key="item.projectTemplateParamId">
                        <!-- 输入框-整数 -->
                        <el-form-item v-if="item.inputType == 1" :label="item.paramName"
                            :prop="'workProjectInstanceParamDtoList.' + index + '.paramValue'"
                            :rules="workorderRules(item)">
                            <el-input v-model.trim="item.paramValue" autocomplete="off" :maxlength="item.maxlength"
                                :placeholder="item.placeholder" :disabled="item.disabled" @input="onChange"
                                clearable></el-input>
                        </el-form-item>
                        <!-- 输入框-浮点 -->
                        <el-form-item v-if="item.inputType == 2" :label="item.paramName"
                            :prop="'workProjectInstanceParamDtoList.' + index + '.paramValue'"
                            :rules="workorderRules(item)">
                            <el-input v-model.trim="item.paramValue" autocomplete="off" :maxlength="item.maxlength"
                                :placeholder="item.placeholder" :disabled="item.disabled" @input="onChange"
                                clearable></el-input>
                        </el-form-item>
                        <!-- 输入框-文本 -->
                        <el-form-item v-if="item.inputType == 3" :label="item.paramName"
                            :prop="'workProjectInstanceParamDtoList.' + index + '.paramValue'"
                            :rules="workorderRules(item)">
                            <el-input v-model.trim="item.paramValue" autocomplete="off" :maxlength="item.maxlength"
                                :placeholder="item.placeholder" :disabled="item.disabled" @input="onChange"
                                clearable></el-input>
                        </el-form-item>
                        <!-- 输入框-文本域 -->
                        <el-form-item v-if="item.inputType == 4" :label="item.paramName"
                            :prop="'workProjectInstanceParamDtoList.' + index + '.paramValue'"
                            :rules="workorderRules(item)">
                            <el-input type="textarea" v-model.trim="item.paramValue" autocomplete="off"
                                :maxlength="item.maxlength" :placeholder="item.placeholder" :disabled="item.disabled"
                                @input="onChange" clearable autosize></el-input>
                        </el-form-item>
                        <!-- 选择器-单选 -->
                        <el-form-item v-if="item.inputType == 5" :label="item.paramName"
                            :prop="'workProjectInstanceParamDtoList.' + index + '.paramValue'"
                            :rules="workorderRules(item)">
                            <el-select v-model="item.paramValue" :placeholder="item.placeholder"
                                :disabled="item.disabled" :filterable="item.canSearch" @change="onChange">
                                <el-option v-for="it in item.optionVoList" :label="it.value" :value="it.key"
                                    :key="it.key"></el-option>
                            </el-select>
                        </el-form-item>
                        <!-- 选择器-多选 -->
                        <el-form-item v-if="item.inputType == 6" :label="item.paramName"
                            :prop="'workProjectInstanceParamDtoList.' + index + '.paramValue'"
                            :rules="workorderRules(item)">
                            <el-select v-model="item.paramValue" :placeholder="item.placeholder"
                                :disabled="item.disabled" :filterable="item.canSearch" @change="onChange" multiple>
                                <el-option v-for="it in item.optionVoList" :label="it.value" :value="it.key"
                                    :key="it.key"></el-option>
                            </el-select>
                        </el-form-item>
                        <!-- 选择器-日期 -->
                        <el-form-item v-if="item.inputType == 7" :label="item.paramName"
                            :prop="'workProjectInstanceParamDtoList.' + index + '.paramValue'"
                            :rules="workorderRules(item)">
                            <el-date-picker v-model="item.paramValue" type="date" :placeholder="item.placeholder"
                                :picker-options="item.dateLimit ? pickerOptions : {}" :disabled="item.disabled"
                                @change="onChange" :editable="false" value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-form-item>
                        <!-- 上传 -->
                        <section v-if="item.inputType == 8">
                            <el-form-item :label="item.paramName"
                                :prop="'workProjectInstanceParamDtoList.' + index + '.paramValue'"
                                :rules="workorderRules(item)">
                                <div class="upload">
                                    <!-- 上传图标 -->
                                    <span class="upload-icon" @click="onUpload(item, index, 1)"
                                        v-if="item.fileList.length < item.limitLength">
                                        <i class="layui-icon layui-icon-addition"></i>
                                    </span>
                                    <!-- 文件列表 -->
                                    <li class="upload-item" v-for="i in item.fileList" :key="i.fileId">
                                        <i class="layui-icon layui-icon-close-fill" @click="onDelFile(i, index, 1)"></i>
                                        <el-image class="upload-box" v-if="readFileType(i.fileUrl)" :src="i.fileUrl"
                                            :preview-src-list="[i.fileUrl]">
                                        </el-image>
                                        <a class="upload-box" v-else :href="i.fileUrl">
                                            <img src="/img/workflow/wd.png" :alt="i.fileName">
                                        </a>
                                    </li>
                                </div>
                            </el-form-item>
                        </section>
                    </div>
                    <!-- 工单参数 -->
                    <div v-for="(workItem, workIndex) in params.workSheetInstanceDtoList" :key="workItem.workSheetType">
                        <p class="work-item-title"
                            v-if="workItem.workSheetInstanceParamVoList && workItem.workSheetInstanceParamVoList.length > 0">
                            {{ workItem.workSheetTypeName }}</p>
                        <div v-for="(item, index) in workItem.workSheetInstanceParamVoList" :key="index">
                            <!-- 输入框-整数 -->
                            <el-form-item v-if="item.inputType == 1" :label="item.paramName"
                                :prop="'workSheetInstanceDtoList.' + workIndex + '.workSheetInstanceParamVoList.' + index + '.paramValue'"
                                :rules="workorderRules(item)">
                                <el-input v-model.trim="item.paramValue" autocomplete="off" :maxlength="item.maxlength"
                                    :placeholder="item.placeholder" :disabled="item.disabled" @input="onChange"
                                    clearable></el-input>
                            </el-form-item>
                            <!-- 输入框-浮点 -->
                            <el-form-item v-if="item.inputType == 2" :label="item.paramName"
                                :prop="'workSheetInstanceDtoList.' + workIndex + '.workSheetInstanceParamVoList.' + index + '.paramValue'"
                                :rules="workorderRules(item)">
                                <el-input v-model.trim="item.paramValue" autocomplete="off" :maxlength="item.maxlength"
                                    :placeholder="item.placeholder" :disabled="item.disabled" @input="onChange"
                                    clearable></el-input>
                            </el-form-item>
                            <!-- 输入框-文本 -->
                            <el-form-item v-if="item.inputType == 3" :label="item.paramName"
                                :prop="'workSheetInstanceDtoList.' + workIndex + '.workSheetInstanceParamVoList.' + index + '.paramValue'"
                                :rules="workorderRules(item)">
                                <el-input v-model.trim="item.paramValue" autocomplete="off" :maxlength="item.maxlength"
                                    :placeholder="item.placeholder" :disabled="item.disabled" @input="onChange"
                                    clearable></el-input>
                            </el-form-item>
                            <!-- 输入框-文本域 -->
                            <el-form-item v-if="item.inputType == 4" :label="item.paramName"
                                :prop="'workSheetInstanceDtoList.' + workIndex + '.workSheetInstanceParamVoList.' + index + '.paramValue'"
                                :rules="workorderRules(item)">
                                <el-input type="textarea" v-model.trim="item.paramValue" autocomplete="off"
                                    :maxlength="item.maxlength" :placeholder="item.placeholder"
                                    :disabled="item.disabled" @input="onChange" clearable autosize>
                                </el-input>

                            </el-form-item>
                            <!-- 选择器-单选 -->
                            <el-form-item v-if="item.inputType == 5" :label="item.paramName"
                                :prop="'workSheetInstanceDtoList.' + workIndex + '.workSheetInstanceParamVoList.' + index + '.paramValue'"
                                :rules="workorderRules(item)">
                                <el-select v-model="item.paramValue" :placeholder="item.placeholder"
                                    :disabled="item.disabled" :filterable="item.canSearch" @change="onChange">
                                    <el-option v-for="it in item.optionVoList" :label="it.value" :value="it.key"
                                        :key="it.key"></el-option>
                                </el-select>
                            </el-form-item>
                            <!-- 选择器-多选 -->
                            <el-form-item v-if="item.inputType == 6" :label="item.paramName"
                                :prop="'workSheetInstanceDtoList.' + workIndex + '.workSheetInstanceParamVoList.' + index + '.paramValue'"
                                :rules="workorderRules(item)">
                                <el-select v-model="item.paramValue" :placeholder="item.placeholder"
                                    :disabled="item.disabled" :filterable="item.canSearch" @change="onChange" multiple>
                                    <el-option v-for="it in item.optionVoList" :label="it.value" :value="it.key"
                                        :key="it.key"></el-option>
                                </el-select>
                            </el-form-item>
                            <!-- 选择器-日期 -->
                            <el-form-item v-if="item.inputType == 7" :label="item.paramName"
                                :prop="'workSheetInstanceDtoList.' + workIndex + '.workSheetInstanceParamVoList.' + index + '.paramValue'"
                                :rules="workorderRules(item)">
                                <el-date-picker v-model="item.paramValue" type="date" :placeholder="item.placeholder"
                                    :picker-options="item.dateLimit ? pickerOptions : {}" :disabled="item.disabled"
                                    @change="onChange" :editable="false" value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </el-form-item>
                            <!-- 上传 -->
                            <section v-if="item.inputType == 8">
                                <el-form-item :label="item.paramName"
                                    :prop="'workSheetInstanceDtoList.' + workIndex + '.workSheetInstanceParamVoList.' + index + '.paramValue'"
                                    :rules="workorderRules(item)">
                                    <div class="upload">
                                        <!-- 上传图标 -->
                                        <span class="upload-icon" @click="onUpload(item, index, 2, workItem, workIndex)"
                                            v-if="item.fileList.length < item.limitLength">
                                            <i class="layui-icon layui-icon-addition"></i>
                                        </span>
                                        <!-- 文件列表 -->
                                        <li class="upload-item" v-for="i in item.fileList" :key="i.fileId">
                                            <i class="layui-icon layui-icon-close-fill"
                                                @click="onDelFile(i, index, 2, workItem, workIndex)"></i>
                                            <el-image class="upload-box" v-if="readFileType(i.fileUrl)" :src="i.fileUrl"
                                                :preview-src-list="[i.fileUrl]">
                                            </el-image>
                                            <a class="upload-box" v-else :href="i.fileUrl">
                                                <img src="/img/workflow/wd.png" :alt="i.fileName">
                                            </a>
                                        </li>
                                    </div>
                                </el-form-item>
                            </section>
                        </div>
                    </div>
                </section>
                <!-- <section class="form-footer">
                    <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
                    <el-button type="primary" @click="onSubmitApprove('form-ref')"
                        v-if="title == '新建工单'">保存并启动项目</el-button>
                </section> -->
            </el-form>

            <div slot="footer">
                <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import {
    ObjToOpt,
} from "@/utils/utils"
import { modelParamColumns } from "@/db/tableColumns"
import {
    WOT, paramType, yesOrNo, inputTypeOpt, inputTypeObj, dataSource, fileType
} from "@/db/objs"
export default {
    props: ['title', 'row'],
    data() {
        return {
            ObjToOpt, WOT, modelParamColumns, paramType, yesOrNo, inputTypeOpt, inputTypeObj, dataSource, fileType,
            editShow: false,
            workProjectTypeList: [], //项目类型列表
            workProjectMoldelList: [], //项目模板列表
            // from表单
            params: {
                // projectInstanceId: getUrlParams().projectInstanceId || "", //项目ID
                workProjectType: "", //项目类型
                projectTemplateId: "", //项目模板
                workProjectInstanceParamDtoList: [],
                workSheetInstanceDtoList: [],
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
                },
            },
            editTitle: '新建工单模板',
            processList: [],//关联审批流程配置列表
            tableData: [
                {
                    paramName: '12',
                    paramType: 1,
                    inputType: 1,
                    required: 1,
                }
            ],
            editParamsTitle: '新增参数',
            editParamsShow: false,
            paramsForm: {},
            rules: {
                
            }
        }
    },
    created() {
        this.getWorkProjectTypeList();
        if (this.title == '查看详情') {
            this.getWorkProjectInfo();
        }
    },
    methods: {
        onAdd() {
            this.editShow = true
            this.editTitle = '新建工单'
        },
        onEdit() {
            this.editShow = true
            this.editTitle = '查看详情'
            this.params = this.row
        },
        // 【识别】文件类型
        readFileType(url) {
            url = url.split('.')
            let type = url[url.length - 1]
            if (type == "jpg" || type == "gif" || type == "png" || type == "bmp") {
                return true
            } else {
                return false
            }
        },

        // 【监听】附件上传
        onUpload(items, indexs, num, workItem, workIndex) {
            let obj;
            // 项目
            if (num == 1) {
                obj = this.params.workProjectInstanceParamDtoList[indexs];
            }
            // 工单
            else if (num == 2) {
                obj = this.params.workSheetInstanceDtoList[workIndex].workSheetInstanceParamVoList[indexs];
            }
            let nowLength = obj.fileList.length;
            let maxlength = items.limitLength - nowLength
            // layer.open({
            //     type: 2,
            //     title: items.paramName,
            //     shadeClose: false,
            //     shade: 0.3,
            //     area: ['500px', '600px'],
            //     content: '/common/upload?maxSize=15mb&maxFiles=' + maxlength + "&fileType=" + items.uploadType,
            //     btn: ['确定', '取消'],
            //     yes: (index, layero) => {
            //         let files = JSON.parse(window[layero.find('iframe')[0]['name']].find())
            //         files = JSON.parse(JSON.stringify(files).replace(/id/g, "fileId").replace(/url/g, "fileUrl").replace(/name/g, "fileName"));
            //         obj.fileList = [...obj.fileList, ...files];
            //         if (obj.fileList) {
            //             let arr = []
            //             for (let i of obj.fileList) {
            //                 arr = [...arr, i.fileId]
            //             }
            //             obj.paramValue = arr.toString();
            //         }
            //         layer.close(index);
            //     }
            // });
        },


        // 【监听】附件删除
        onDelFile(items, indexs, num, workItem, workIndex) {
            let obj;
            // 项目
            if (num == 1) {
                obj = this.params.workProjectInstanceParamDtoList[indexs];
            }
            // 工单
            else if (num == 2) {
                obj = this.params.workSheetInstanceDtoList[workIndex].workSheetInstanceParamVoList[indexs];
            }
            let index = obj.fileList.findIndex(i => i.fileId == items.fileId);
            obj.fileList.splice(index, 1);
            if (obj.fileList) {
                let arr = []
                for (let i of obj.fileList) {
                    arr = [...arr, i.fileId]
                }
                obj.paramValue = arr.toString();
            }
        },


        // 【请求】项目类型下拉列表
        getWorkProjectTypeList() {
            let data = {};
            // utils.ajaxSilent(r.api + "/workProject/template/workProjectTypes", data, res => {
            //     if (res.success) {
            //         this.workProjectTypeList = res.data;
            //     }
            // })
        },

        // 【请求】项目类型下模板列表
        getWorkProjectModelList() {
            let data = {
                workProjectType: this.params.workProjectType
            };
            // utils.ajaxSilent(r.api + "/workProject/template/list", data, res => {
            //     if (res.success) {
            //         this.workProjectMoldelList = res.data;
            //         if (title == '新建工单') {
            //             this.params.projectTemplateId = "";
            //             this.params.workProjectInstanceParamDtoList = [];
            //             this.params.workSheetInstanceDtoList = [];
            //         }
            //     }
            // })
        },

        // 【请求】新建回显
        getTemplateInfo() {
            let data = {
                projectTemplateId: this.params.projectTemplateId
            };
            // utils.ajaxSilent(r.api + "/workProject/instance/edit", data, res => {
            //     if (res.success) {
            //         this.params.workProjectInstanceParamDtoList = res.data.workProjectInstanceParamVoList;
            //         this.params.workSheetInstanceDtoList = res.data.workSheetInstanceVoList;
            //     }
            // })
        },

        // 【请求】编辑回显
        getWorkProjectInfo() {
            let data = {
                projectInstanceId: this.params.projectInstanceId
            };
            // utils.ajaxLoad(r.api + "/workProject/instance/edit", data, res => {
            //     if (res.success) {
            //         this.params.workProjectType = res.data.workProjectType;
            //         this.params.projectTemplateId = res.data.projectTemplateId;
            //         this.params.workProjectInstanceParamDtoList = res.data.workProjectInstanceParamVoList;
            //         this.params.workSheetInstanceDtoList = res.data.workSheetInstanceVoList;
            //         this.getWorkProjectModelList();
            //     }
            // })
        },

        // 【请求】新建项目
        getAddWorkProject() {
            let data = JSON.parse(JSON.stringify(this.params).replace(/workSheetInstanceParamVoList/g, "workSheetInstanceParamDtoList"));
            // utils.ajaxLoad(r.api + "/workProject/instance/add", data, res => {
            //     if (res.success) {
            //         layer.msg(res.msg);
            //         parent.layer.closeAll(); //关闭父级
            //     }
            // })
        },


        // 【请求】编辑项目
        getEditWorkProject() {
            let data = JSON.parse(JSON.stringify(this.params).replace(/workSheetInstanceParamVoList/g, "workSheetInstanceParamDtoList"));
            // utils.ajaxLoad(r.api + "/workProject/instance/update", data, res => {
            //     if (res.success) {
            //         layer.msg(res.msg);
            //         parent.layer.closeAll(); //关闭父级
            //     }
            // })
        },

        // 【监听】强制更新
        onChange() {
            this.$forceUpdate()
        },

        // 【监听】表单提交
        onSubmit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.title == '新建工单') {
                        this.getAddWorkProject();
                    } else if (this.title == '查看详情') {
                        this.getEditWorkProject();
                    }
                } else {
                    return false;
                }
            });
        },

        // 【监听】保存并启动项目
        onSubmitApprove(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let data = JSON.parse(JSON.stringify(this.params).replace(/workSheetInstanceParamVoList/g, "workSheetInstanceParamDtoList"));
                    // utils.ajaxLoad(r.api + "/workProject/instance/addAndApprove", data, res => {
                    //     if (res.success) {
                    //         layer.open({
                    //             type: 2,
                    //             title: [`提交审批`, 'text-align: center;padding:0;font-size:18px;background:#fff'],
                    //             shadeClose: false,
                    //             closeBtn: 2,
                    //             shade: 0.3,
                    //             area: ['950px', '600px'],
                    //             content: '/oa/workorder/workorderApprove?relationType=2&processId=' + res.data.processId + '&businessId=' + res.data.businessId,
                    //             end: () => {
                    //                 this.getTableList();
                    //             }
                    //         });
                    //     }
                    // })
                } else {
                    return false;
                }
            });
        },

    }

}
</script>
