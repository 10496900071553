<template>
    <div class="center_container">
        <el-tabs class="tabs" v-model="tableParams.type">
            <el-tab-pane v-for="(item, index) in tabList" :key="index" :label="item.title" :name="item.title">
                <div slot="label">
                    <el-badge :value="item.value" class="item">
                        <span>{{ item.title }}</span>
                    </el-badge>
                </div>
            </el-tab-pane>
        </el-tabs>
        <div class="search">
            <el-row :gutter="10" style="margin-bottom: 10px;">
                <el-col :span="4">
                    <el-input placeholder="请输入发起人搜索" v-model="tableParams.applyUserName" clearable @input="kwChange">
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-input placeholder="请输入当前审批人搜索" v-model="tableParams.approveEmpName" clearable @input="kwChange">
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-input placeholder="请输入工单编号" v-model="tableParams.projectCode" clearable @input="kwChange">
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-input placeholder="请输入摘要内容搜索" v-model="tableParams.search" clearable @input="kwChange">
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-select v-model="tableParams.paymentType" placeholder="选择审批状态" @change="reload" clearable>
                        <el-option v-for="item in ObjToOpt(auditStatus)" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="4">
                    <el-select v-model="tableParams.paymentType" placeholder="选择工单类型" @change="reload" clearable>
                        <el-option v-for="(item, index) in workOrderType" :key="index" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :span="4">
                    <el-cascader v-model="tableParams.deptIds" :options="departmentList" placeholder="请选择部门"
                        :props="props" @change="reload"></el-cascader>
                </el-col>
                <el-col :span="8">
                    <el-date-picker v-model="dateToDate" type="daterange" align="center" unlink-panels
                        range-separator="~" start-placeholder="发起时间" end-placeholder="发起时间" @change="goSelectDate()"
                        clearable value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-col>
                <el-col :span="8">
                    <el-date-picker v-model="dateToDate" type="daterange" align="center" unlink-panels
                        range-separator="~" start-placeholder="完成时间" end-placeholder="完成时间" @change="goSelectDate()"
                        clearable value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-col>
                <el-col :span="2">
                    <el-button type="primary" @click="reload()">保存</el-button>
                </el-col>
                <el-col :span="2">
                    <CenterInfo :title="'新建工单'" v-has="'center_add'"/>
                </el-col>
            </el-row>
        </div>
        <Table :tableData="tableData" :column="workCenterColumns" border :headerCellStyle="headerCellStyle">
            <template slot-scope="scope" slot="approveStatusNameSlot">
                <ExamineInfo :row="scope.row" :title="scope.row.approveStatusName"/>
            </template>
            <template slot-scope="scope" slot="operation">
                <CenterInfo :title="'查看详情'" v-has="'center_detail'"/>
                <el-button type="text" size="medium" @click.stop="onDel(scope.row)" v-has="'center_del'">删除</el-button>
            </template>
        </Table>
        <div class="table-footer">
            <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
                :page-size="tableParams.size" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
                :current-page.sync="currentPage" @current-change="onCurrentPage">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { auditStatus } from "@/db/objs.js";
import { ObjToOpt } from "@/utils/utils";
import { workCenterColumns } from "@/db/tableColumns";
import CenterInfo from "./child/centerInfo.vue";
import ExamineInfo from "./child/examineInfo.vue";
import { queryDept } from "@/api/organization/department.js";
export default {
    components: {
        CenterInfo,ExamineInfo
    },
    data() {
        return {
            ObjToOpt, auditStatus, workCenterColumns,
            headerCellStyle: {
                background: '#FAFAFA',
                color: '#000000',
            },
            props: {
                value: 'deptId',
                label: 'deptName',
                children: 'childDeptVoList',
                multiple: true,
                checkStrictly: true,
                emitPath: false
            },
            departmentList: [],//部门列表
            workOrderType: [],//工单类型
            dateToDate: [],//日期范围
            tableParams: {

            },
            tabList: [
                {
                    title: '待审核',
                    value: 12,
                },
                {
                    title: '我已处理',
                    value: 12,
                },
                {
                    title: '我已发起',
                    value: 12,
                },
                {
                    title: '我已收到',
                    value: 12,
                },
                {
                    title: '部门任务',
                    value: 12,
                },
            ],
            tableData: [
                {
                    id: 1,
                    projectCode: '张三',
                    workProjectTypeName: '123456789',
                    projectName: '研发部',
                    applyUserName: 1,
                    mobile: '2020-12-12', 
                    approveStatusName:'审批中'
                }
            ],
            currentPage: 1, // 当前页
            total: 0, // 总条数  
        }
    },
    created() {
        this.queryDeptList();
    },
    methods: {
        // 【监听】表格分页点击
        onCurrentPage(page) {
            this.tableParams.page = page;
            this.getTableList();
        },

        // 【监听】表格条数点击
        onSizeChange(size) {
            this.tableParams.size = size;
            this.reload();
        },

        // 查询部门列表
        queryDeptList() {
            const params = {
                deptStatus: ''
            };
            queryDept(params).then(res => {
                this.departmentList = [res.data];
            });
        },
        kwChange: _.debounce(function () {
            this.reload();
        }, 500),
        reload() {
            this.getTableList()
        },
        getTableList() {

        },
        // 新建工单
        add() {

        }
    }

}
</script>

<style lang="scss" scoped>
.center_container {
    .tabs {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }

    /deep/.el-tabs__header {
        width: 100%;
        padding: 0.15rem;
    }

    /deep/.el-tabs__item {
        width: 100px;
        height: 0.6rem !important;
        line-height: 0.4rem !important;
        text-align: center;
    }

    /deep/.el-tabs__item {
        padding: 0.1rem;
    }

    .search {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
    }

    .table-footer {
        margin-top: 0.1rem;
        display: flex;
        justify-content: flex-end;
    }
}
</style>