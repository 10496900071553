<template>
    <div class="examineInfo">
        <el-button type="text" size="medium" @click.stop="onEdit()">{{ title }}</el-button>
        <el-drawer :title="drawerTitle" :visible.sync="editParamsShow" :close-on-click-modal="false" append-to-body
            width="50%" center>
            <div class="baseInfo_container">
                <div style="display: flex;margin-bottom: 10px;">
                    <el-avatar :src="baseInfo.circleUrl"></el-avatar>
                    <div style="margin-left: 10px;">
                        <div style="font-size: 14px;color: #666666;">{{ baseInfo.userName || '-' }}</div>
                        <div style="font-size: 14px;color: #666666;">{{ baseInfo.approveStatusName }}</div>
                    </div>
                </div>
                <el-descriptions title="" :column="1" :labelStyle="{ 'color': '#333' }">
                    <el-descriptions-item label="工单编号">{{ row.id }}</el-descriptions-item>
                    <el-descriptions-item label="工单类型">{{ row.workProjectTypeName }}</el-descriptions-item>
                    <el-descriptions-item label="工单归属门店">{{ row.projectName }}</el-descriptions-item>
                    <el-descriptions-item label="工单审批状态">{{ row.status }}</el-descriptions-item>
                    <el-descriptions-item label="报销总额">{{ '￥' + row.totalAmount }}</el-descriptions-item>
                </el-descriptions>
            </div>
            <div class="process">
                <el-steps direction="vertical" :active="row.approveStatus" finish-status="success">
                    <el-step v-for="(item, index) in approveList" :key="index" icon="none">
                        <template slot="icon">
                            <el-avatar :src="item.avatar" style="width: 34px;height: 34px"></el-avatar>
                        </template>
                        <template slot="title">
                            <span style="font-size: 12px;color: #999999;">{{ `${item.applyRole} (${item.approveStatusName})` }}</span>
                        </template>
                        <template slot="description">
                            <div style="display: flex;justify-content: space-between;">
                                <div style="font-size: 12px;color: #999999;">{{ item.approveUserName }}</div>
                                <div style="font-size: 12px;color: #999999;">{{ item.applyStartTime }}</div>
                            </div>
                        </template>
                    </el-step>
                </el-steps>
            </div>



        </el-drawer>


    </div>
</template>
<script>
import {
    ObjToOpt,
} from "@/utils/utils"

export default {
    props: ['title', 'row'],
    data() {
        return {
            editParamsShow: false,
            drawerTitle: 'xxxx发起的xxxx工单',
            baseInfo: {},
            approveList: [
                {
                    applyRole: '发起人',
                    approveStatusName: '已提交',
                    applyStartTime: '2022-12-12 12:12:12',
                    avatar: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fci.xiaohongshu.com%2F5166ff15-65ed-0142-5196-4a2eb4756f3c%3FimageView2%2F2%2Fw%2F1080%2Fformat%2Fjpg&refer=http%3A%2F%2Fci.xiaohongshu.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1739618085&t=d746e93cd9c9e41434fc092211bf9e2b',
                    approveUserName: '张三',
                    approveTime: '2022-12-12 12:12:12'
                },
                {
                    applyRole: '审核人',
                    approveStatusName: '已提交',
                    applyStartTime: '2022-12-12 12:12:12',
                    avatar: 'https://gimg2.baidu.com/image_search/src=https%3A%2F%2Fimg.zcool.cn%2Fcommunity%2F66ac2a922faaev67m8qu0r7660.jpg%3FimageMogr2%2Fauto-orient%2Fthumbnail%2F1280x%253e%2Fsharpen%2F0.5%2Fquality%2F100&refer=http%3A%2F%2Fimg.zcool.cn&app=2002&size=f10000,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1739673712&t=b8cdff56a2572036dc38f3cd583ccf45',
                    approveUserName: '李四',
                    approveTime: '2022-12-12 12:12:12'
                },
                {
                    applyRole: '审核人',
                    approveStatusName: '已提交',
                    applyStartTime: '2022-12-12 12:12:12',
                    avatar: 'https://img0.baidu.com/it/u=2424289477,2047538215&fm=253&app=120&f=JPEG?w=475&h=475',
                    approveUserName: '王五',
                    approveTime: '2022-12-12 12:12:12'
                },
                {
                    applyRole: '审核人',
                    approveStatusName: '已提交',
                    applyStartTime: '2022-12-12 12:12:12',
                    avatar: 'https://img0.baidu.com/it/u=2424289477,2047538215&fm=253&app=120&f=JPEG?w=475&h=475',
                    approveUserName: '王五',
                    approveTime: '2022-12-12 12:12:12'
                },
                {
                    applyRole: '审核人',
                    approveStatusName: '审批中',
                    applyStartTime: '2022-12-12 12:12:12',
                    avatar: 'https://img0.baidu.com/it/u=2424289477,2047538215&fm=253&app=120&f=JPEG?w=475&h=475',
                    approveUserName: '王五',
                    approveTime: '2022-12-12 12:12:12'
                },
                {
                    applyRole: '抄送人',
                    approveStatusName: '待抄送',
                    applyStartTime: '2022-12-12 12:12:12',
                    avatar: 'https://gimg2.baidu.com/image_search/src=https%3A%2F%2Fimg.zcool.cn%2Fcommunity%2F01rvumlqxtqvdubauy6yte3238.jpg%3FimageMogr2%2Fauto-orient%2Fthumbnail%2F1280x%253e%2Fsharpen%2F0.5%2Fquality%2F100&refer=http%3A%2F%2Fimg.zcool.cn&app=2002&size=f10000,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1739673712&t=444f0114d85a5c5b48af0baac89ac378',
                    approveUserName: '赵六',
                    approveTime: '2022-12-12 12:12:12'
                },
            ],
        }
    },
    mounted() {

    },
    methods: {
        onEdit() {
            this.editParamsShow = true
            this.baseInfo = this.row

        },

    }

}
</script>
<style lang="scss" scoped>
.examineInfo {
    display: inline-block;
    margin-right: 10px;
}

.baseInfo_container {
    border-bottom: 1px solid #999;
}

/deep/.el-drawer__body {
    padding: 0 20px 20px 20px !important;
}

/deep/.el-descriptions-item__content {
    justify-content: flex-end !important;
}

.process {
    margin: 20px 0;

    /deep/.el-step__icon {
        width: 34px;
        height: 34px;
    }

    /deep/.el-step__icon.is-icon {
        width: 34px;
    }

    /deep/.el-step__main {
        padding-left: 20px;
    }

    /deep/.el-step__line {
        left: 16px;
    }
}
</style>